import React, { useEffect } from "react";
import Summary from "./components/summary";
import TrackProgress from "./components/track_progress";
import DrawerRouterContainer from "components/newDrawer";
import { Header } from "components/Header";
import { useSetState, useTrackedState } from "store/store";
import { Block } from "baseui/block";
import useExportPdf from "hooks/useExportPdf";
import { useStyletron } from "baseui";
import BreadCrumb from "./components/breadcrumb";
import { LearnerProfile, MentorProfile } from "./components/about";
import StudentAPI from "services/requests/student_api";
import { useHistory } from "react-router-dom";
import { replaceValuesInJson } from "services/requests/learnerList";
import { mapStudentData } from "utils/app-utils";
//import fetchCohortStudentData from "services/requests/cohort_student_data";

const MAIN_STYLE = (theme) => ({
  [theme.mediaQuery.small]: {
    padding: "16px",
  },
  [theme.mediaQuery.medium]: {
    padding: "40px",
  },
});

export default function TrackSummaryComponent() {
  const [css, theme] = useStyletron();
  const { exportPdf, pdfComponentRef } = useExportPdf(new Date().getFullYear());
  const setState = useSetState();
  const state = useTrackedState();
  const history = useHistory();
  //const [trackData, setTrackData] = React.useState(null);
  let resp = {};

  async function stdentTrack() {
    let learner_email = "";
    try {
      if (
        localStorage.getItem("role") === "ADMIN" ||
        localStorage.getItem("role") === "TENANT OWNER" ||
        localStorage.getItem("role") === "MENTOR"
      ) {
        learner_email = JSON.parse(localStorage.getItem("track")).email_id;
      } else if (localStorage.getItem("role") === "LEARNER") {
        learner_email = localStorage.getItem("email");
      }
      const response = await StudentAPI(
        localStorage.getItem("email"),
        learner_email
      );
      const replaceResponse = await replaceValuesInJson();
      if (response.status === 200) {
        const studentOverviewInitial =
          // response.data[0]?.learner_profile_list[0];
          response.data[0]?.learner_profile_list;
        const studentOverview = mapStudentData(
          studentOverviewInitial,
          replaceResponse?.data[0]?.learner_profile_list
        );
        localStorage.setItem(
          "learner_data",
          // response.data[0].learner_profile_list.length
          studentOverview?.length
        );
        console.log(studentOverview);
        // if (response.data[0].learner_profile_list.length > 0) {
        if (studentOverview?.length > 0) {
          console.log("student", studentOverview);
          // localStorage.setItem("track", JSON.stringify(studentOverview));
          localStorage.setItem("track", JSON.stringify(studentOverview[0]));
          setState(
            (prev) => ({
              ...prev,
              // track: studentOverview,
              track: studentOverview[0],
              isFetchTrackData: true,
            }),
            () => {
              resp = localStorage.getItem("track");
            }
          );
        } else {
          history.push("/landing");
        }
      } else {
        //console.log("something went wrong");
        history.push("/landing");
      }
    } catch (e) {
      console.log(e);
      setState((prev) => ({
        ...prev,
        track: {},
        isFetchTrackData: false,
      }));
    }
  }

  useEffect(() => {
    if (localStorage.getItem("logout") === "1") {
      history.push("/login");
    }
    localStorage.setItem("currentPage", "tracksummary");
    // if (!state.isFetchTrackData) {
    stdentTrack();
    // }
    //axlpData()
  }, []);

  if (state?.track) {
    localStorage.setItem("track", JSON.stringify(state.track));
  }
  resp = JSON.parse(localStorage.getItem("track"));

  // const axlpData = () => {
  //   if (state?.track) {
  //     localStorage.setItem("track", JSON.stringify(state.track));
  //   }
  //   resp = JSON.parse(localStorage.getItem("track"));

  //   const learnerEmail = localStorage.getItem("email");
  //   fetchCohortStudentData(learnerEmail, "LEARNER")
  //     .then(function (response) {
  //       if (response.status === 200) {

  //         resp = resp?.learning_path?.phase_list[0]?.macro_certification_list.map((obj) => {
  //           obj?.course_list.map((ob) => {
  //             let vals = 0; let diff = 0;
  //             let valueActual = 0 ; let valueTotal = 0;
  //             let bb = parseInt(ob?.course_id)

  //           response.data?.map(function (el) {
  //             el?.cohorts_data?.[0].course.find((element) => {
  //               if (element.course_id === bb ) {
  //                 console.log("course name ",element.course_name,element.course_id)
  //                 let total_submitted = element?.student_data[0].assignment_submitted_detail.length
  //                 let submitted_data = element?.student_data[0].assignment_submitted_detail?.filter((ot) => {
  //                  return ot?.outcome === "PASS"
  //                 })
  //                 valueActual = valueActual + submitted_data?.length
  //                 valueTotal = valueTotal + total_submitted
  //                 vals =  element?.student_data[0].lessons
  //                 vals.map((ls) => {
  //                   ls?.room?.map((rm) => {
  //                     let vals_actual = parseInt(rm?.room_progress.split("\/")[0])
  //                     let vals_total = parseInt(rm?.room_progress.split("\/").pop())
  //                     valueActual = valueActual + vals_actual
  //                     valueTotal = valueTotal + vals_total
  //                     diff = valueTotal - valueActual
  //                     console.log("total--0---",valueActual,valueTotal,diff)
  //                   })
  //               })
  //             }
  //             });
  //           });
  //             ob.course_progress.progress_actual =  ob?.course_progress?.progress_actual + valueActual
  //             ob.course_progress.total_questions = ob?.course_progress?.total_questions + valueTotal
  //             ob.course_progress.actual_percent = (ob.course_progress.total_questions === 0 ?  0 : Math.ceil((ob.course_progress.progress_actual *100)/ob.course_progress.total_questions))
  //             ob.course_progress.expected_percent = (ob.course_progress.total_questions === 0 ?  0 :  Math.ceil(((ob.course_progress.progress_expected+ valueTotal)*100)/ob.course_progress.total_questions))
  //             ob.course_progress.behind_percent = ob.course_progress.actual_percent < ob.course_progress.expected_percent ? (ob.course_progress.expected_percent - ob.course_progress.actual_percent) : 0
  //             ob.course_progress.exceeds_percent = ob.course_progress.actual_percent > ob.course_progress.expected_percent ? (ob.course_progress.actual_percent - ob.course_progress.expected_percent)  : 0

  //           setTrackData(resp)
  //         })
  //         })

  //       }
  //     })

  //   }

  return (
    <Block>
      <Header />
      <DrawerRouterContainer>
        <Block className={css(MAIN_STYLE(theme))}>
          <BreadCrumb exportPdf={exportPdf} user={resp || {}} />
          <Block ref={pdfComponentRef}>
            <Summary track={resp} />
            <Block
              display="flex"
              flexDirection={["column", "column", "column", "row"]}
              gridGap={["16px", "16px", "20px"]}
            >
              <Block flex="1">
                <TrackProgress />
              </Block>
              <Block
                width={["initial", "initial", "initial", "20%"]}
                display="flex"
                flexDirection="column"
                gridGap="20px"
              >
                <LearnerProfile track={resp} />
                <MentorProfile track={resp} />
              </Block>
            </Block>
          </Block>
        </Block>
      </DrawerRouterContainer>
    </Block>
  );
}
